<template>
  <el-menu :default-openeds="opens" style="min-height: 100%; overflow-x: hidden"
           :style="{ '--sidebar-bg-color': sidebarBackgroundColor }"
           text-color="#fff"
           active-text-color="#ffd04b"
           :collapse-transition="false"
           :collapse="isCollapse"
           router>
    <div style="height: 60px; line-height: 60px; text-align: center">
      <img src="../assets/logo.png" alt="" style="width: 50px; position: relative; top: 5px;">
      <b style="color: #f5f4f4; margin-left: 1px; cursor: pointer;" v-show="logoTextShow" @click="goToHomePage">凌空物语智能科技</b>
    </div>
    <div v-for="item in menus" :key="item.id">
      <div v-if="item.path">
        <el-menu-item :index="item.path">
          <i :class="item.icon"></i>
          <span slot="title">{{ item.name }}</span>
        </el-menu-item>
      </div>
      <div v-else>
        <el-submenu :index="item.id + ''">
          <template slot="title">
            <i :class="item.icon"></i>
            <span slot="title">{{ item.name }}</span>
          </template>
          <div  v-for="subItem in item.children" :key="subItem.id">
            <el-menu-item :index="subItem.path">
              <i :class="subItem.icon"></i>
              <span slot="title">{{ subItem.name }}</span>
            </el-menu-item>
          </div>
        </el-submenu>
      </div>
    </div>
  </el-menu>
</template>

<script>
export default {
  name: "Aside",
  props: {
    isCollapse: Boolean,
    logoTextShow: {
      type: [Boolean, String],
      default: false
    }
  },
  data() {
    return {
      menus: localStorage.getItem("menus") ? JSON.parse(localStorage.getItem("menus")) : [],
      opens: localStorage.getItem("menus") ? JSON.parse(localStorage.getItem("menus")).map(v => v.id + '') : [],
     // logoTextShow: true,
      // 假设默认显示
      sidebarBackgroundColor: ''
    }
  },
  methods: {
    goToHomePage() {
      this.$router.push('/home');
    },
  },
}
</script>

<style>
:root {
  --sidebar-bg-color: rgb(4, 26, 103);
  --sidebar-text-color: #f5f4f4;
  --sidebar-active-color: #d2356c;
  --submenu-hover-color: rgb(210, 53, 108);
}

.el-menu {
  background-color: var(--sidebar-bg-color);
}

.el-menu-item,
.el-menu-item span {
  color: var(--sidebar-text-color);
}

.el-menu-item.is-active {
  background-color: var(--sidebar-active-color) !important;
}

.el-menu-item:hover {
  background-color: var(--submenu-hover-color) !important;
}

.el-submenu__title:hover {
  background-color: var(--submenu-hover-color) !important;
}
</style>
